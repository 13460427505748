import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import Card from './index-card';


export default function ResponsiveGrid() {

  const solutions = [
    {
      title: 'Enterprise Systems',
      desciption: 'All in one solutions that integrate key business processes like finance, HR, and supply chain into a unified system, streamlining operations, improving efficiency, and providing real-time insights for informed decision-making.',
      icon: 'enterprise',
      target: 'solutions/enterprise'
    },
    {
      title: 'Healthcare Management',
      desciption: 'Streamline operations, enhances patient care, and ensures regulatory compliance through integrated software. It offers seamless scheduling, billing, and patient records management, all in one platform.',
      icon: 'healthcare',
      target: 'solutions/healthcare'
    },
    {
      title: 'Education Management',
      desciption: 'Solution for management of administrative tasks, enhances student engagement, and simplifies curriculum management. Designed for educational institutions of all sizes, it offers features such as attendance tracking, grade management, and communication tools.',
      icon: 'education',
      target: 'solutions/education'
    },
    {
      title: 'Small & Medium Enterprises',
      desciption: 'Solutions to help the SME run their business operations including procurement, customer relations database (CRM), vendor management. Solutions for Legal, Auto, Manufacturing.',
      icon: 'sme',
      target: 'solutions/sme'
    },
    {
      title: 'Release Automation',
      desciption: 'Automation heps you optimize software development and release processes, deliver higher-quality software, and respond quickly to market demands, all while keeping costs manageable.',
      icon: 'devops',
      target: 'solutions/devops'
    },
    {
      title: 'Data Management',
      desciption: 'Data management solutions to help you organization siloed data into single data lakes to help you make insightful business decisions. Including Data Catalogues, Master Data Managers, Data Engineering and modern datatabases',
      icon: 'data',
      target: 'solutions/data'
    },
    {
      title: 'Business Automation',
      desciption: 'Automate essential business processes, enhancing operational efficiency across various departments including finance, accounting, human resources, inventory management, and customer relationship management.',
      icon: 'automation',
      target: 'solutions/automation'
    },
    {
      title: 'Artificial Intelligence',
      desciption: 'Artificial Intelligence (AI) helps transforms how companies operate, offering tools to optimize performance, increase productivity, and provide better customer experiences.',
      icon: 'finance',
      target: 'solutions/nesis'
    }
  ]

  return (
    <Box sx={{ mt: 10, mb: 10 }}>
      <Typography variant="h4" align="center" gutterBottom>
        <strong>Comprehensive Solutions to Support Your Business Operations</strong>
      </Typography>
      <Typography variant="h6" align="center" gutterBottom>
        <strong>A wide selection of affordable business solution to support every aspect of your business operation</strong>
      </Typography>
      <Box sx={{ flexGrow: 1, mt: 5 }}>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, md: 16 }}>
          {solutions.map((solution, index) => (
            <Grid key={index} size={{ xs: 2, sm: 4, md: 4 }}>
              <Card title={solution.title} description={solution.desciption} icon={solution.icon} target={solution.target} />
            </Grid>
          ))}
        </Grid>
      </Box>
      </Box>
  );
}
