import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import * as classes from '../../custom-styles/Home.module.css'
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { device } from '../../utils/breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

const HeaderContent = styled.div`
    padding: 20px 20px !important;
    margin:0;
    padding: 12px 0;
        & h1{
    font-size: 40px;
    font-weight: 550;
    margin:0;
    padding: 12px 0;
    @media ${device.tablet} {
        font-size: 40px;
        font-weight: 550;
        }
}
        & p{    
    font-size: 26px;
    margin:0;
    padding: 12px 0;
    @media ${device.tablet} {
        font-size: 18px;
        font-weight: 400;
    }
}
`;


export default function HomePage() {

  return (
    <Container className={`${classes.py50}`}>
      <Row className="align-items-center">
        <Col lg={6}>
          <HeaderContent>
            <Typography variant="h3" gutterBottom><strong>Solutions for Multiple Business Types</strong></Typography>
            <Typography variant="h5" gutterBottom>Enterprise . Healthcare . Education . SMEs . Automation . Data . Artificial Intelligence</Typography>
          </HeaderContent>
        </Col>
        <Col className={`${classes.backgroundimagescover}`} lg={6}>
          <StaticImage src={'../../images/solutions-index-1.jpg'}  alt='' />
        </Col>
      </Row>
    </Container>
  )
}
